<template>
  <div>
    <Breadcrumbs />
    <section class="login-page">
      <b-container>
        <b-row class="login-wrap">
          <b-col lg="6" xl="6" class="login-component">
            <div class="form-title">{{ $t("login") }}</div>
            <div class="login-inner">
              <b-form-group
                id="email-group"
                :label="$t('req_email')"
                label-for="email"
              >
                <b-form-input
                  id="email"
                  v-model="loginData.email"
                  type="email"
                  :state="form.emailState"
                  aria-describedby="email-feedback"
                ></b-form-input>
                <b-form-invalid-feedback id="email-feedback">
                  {{ form.emailError }}
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group
                id="password-group"
                :label="$t('req_password')"
                label-for="password"
                class="mb-0 password-field"
              >
                <b-form-input
                  id="password"
                  v-model="loginData.password"
                  :type="form.passwordFieldType"
                  :state="form.passwordState"
                  aria-describedby="password-feedback"
                ></b-form-input>
                <b-form-invalid-feedback id="password-feedback">
                  {{ form.passwordError }}
                </b-form-invalid-feedback>
                <b-link to="/forgot-password" class="forgot-link">{{
                  $t("password_forgot")
                }}</b-link>
                <a
                  href="javascript:void(0)"
                  class="toggle-pass"
                  @click="toggleShowPass"
                  :class="{ show: form.passwordFieldType === 'text' }"
                >
                  <i class="fas fa-eye show-eye"></i>
                  <i class="fas fa-eye-slash hide-eye"></i>
                </a>
              </b-form-group>
              <b-form-checkbox id="checkbox" name="checkbox"
                >Onthoud mij</b-form-checkbox
              >
            </div>

            <div class="d-flex justify-content-between align-items-center">
              <b-button
                type="button"
                variant="primary"
                @click="loginForm"
                class="text-white ml-20 form-submit-btn"
                >{{ $t("login") }}</b-button
              >
            </div>
          </b-col>
          <b-col lg="6" xl="6" class="create-account-info">
            <div class="form-title">{{ $t("new_customers") }}</div>
            <div class="login-inner">
              <label>Maak kosteloos een account aan en profiteer van:</label>
              <ul>
                <li>
                  <i class="fa fa-check-circle"></i> Sneller afhandelen van
                  bestellingen
                </li>
                <li>
                  <i class="fa fa-check-circle"></i> Meer dan een adres
                  registreren
                </li>
                <li>
                  <i class="fa fa-check-circle"></i> Volgen van je bestellingen
                </li>
                <li><i class="fa fa-check-circle"></i> En meer!</li>
              </ul>
            </div>
            <div class="d-flex justify-content-between align-items-center">
              <b-button
                @click="$router.push({ name: 'create-account' })"
                variant="primary"
                >{{ $t("create_account") }}</b-button
              >
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>
  </div>
</template>

<script>
import Breadcrumbs from "@/base/core/components/common/Breadcrumbs";
import Login from "@storefront/core/modules/user/mixins/login";

export default {
  name: "Login",
  mixins: [Login],
  components: {
    Breadcrumbs,
  },
  metaInfo() {
    return {
      meta: [
        { charset: "utf-8" },
        { name: "viewport", content: "width=device-width, initial-scale=1" },
        { name: "title", content: this.$t("login") },
        { name: "keywords", content: this.$t("login") },
        { name: "description", content: this.$t("login") },
      ],
      title: this.$t("login"),
    };
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters["user/getIsLoggedIn"];
    },
  },
  watch: {
    isLoggedIn() {
      if (this.$store.getters["user/getIsLoggedIn"] == true) {
        this.$router.push({ name: "account" });
      }
    },
  },
  mounted() {
    const bcrumb = { current: this.$t("login"), routes: [] };
    this.$store.commit("breadcrumbs/set", bcrumb);
  },
  data() {
    return {
      form: {
        email: "",
        emailState: null,
        emailError: "",
        password: "",
        passwordState: null,
        passwordError: "",
        passwordFieldType: "password",
      },
    };
  },
  methods: {
    formSubmit() {},
    loginForm() {
      let formValid = true;

      if (this.loginData.email == "") {
        this.form.emailState = false;
        this.form.emailError = this.$t("email_is_required_field");
        formValid = false;
      } else if (!this.emailReg.test(this.loginData.email)) {
        this.form.emailState = false;
        this.form.emailError = this.$t("email_is_invalid_field");
        formValid = false;
      } else {
        this.form.emailState = true;
      }

      if (this.loginData.password == "") {
        this.form.passwordState = false;
        this.form.passwordError = this.$t("password_is_required_field");
        formValid = false;
      } else {
        this.form.passwordState = true;
      }

      if (formValid) this.login();
    },
    toggleShowPass() {
      this.form.passwordFieldType =
        this.form.passwordFieldType === "password" ? "text" : "password";
    },
  },
};
</script>

<style lang="scss"></style>
